import Concordance from '@UI/concordance/Concordance';
import Goal from '@UI/goal/Goal';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDistributionMode } from '@store/distribute/distributeSelectors';
import TermModal from '@UI/termModal/TermModal';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import Section from '../section/Section';
import GoalListSkeleton from './goalsTabSkeleton/GoalsTabSkeleton';

interface GoalListProps {
  goalList: any;
  onOpenEditGoalModal: ((goal: any) => void) | null;
  nonDerivedOptions: ((isFirstItem: boolean, item: any) => void) | null;
  removeSection: ((section: any) => void) | null;
}

const GoalList: React.FC<GoalListProps> = ({
  goalList,
  onOpenEditGoalModal,
  nonDerivedOptions,
  removeSection,
}) => {
  const isDistributionMode = useSelector(selectIsDistributionMode);
  const termModalRef = useRef();
  if (!goalList) {
    return <GoalListSkeleton />;
  }

  return (
    <div className="goals-tab">
      {goalList?.concordance && (
        <Concordance key={goalList.concordance.key} concordance={goalList.concordance} />
      )}
      <div className="non-section-goals">
        {goalList?.goals.map((goal, index) => (
          <Goal
            key={goal.key}
            goal={goal}
            parentKey={goalList.$$meta.permalink}
            isFirstItem={index === 0}
            onShowEditGoalModal={onOpenEditGoalModal}
            onShowNonderivedModal={nonDerivedOptions}
            termModalRef={termModalRef}
          />
        ))}
      </div>

      {goalList?.sections.map((section, index) => {
        const isFirstItem = index === 0 && (!goalList.goals || goalList.goals.length === 0);
        return (
          <Section
            key={section.key || index}
            section={section}
            isFirstItem={isFirstItem}
            isSubSection={false}
            disabled={isDistributionMode}
            onShowEditGoalModal={onOpenEditGoalModal}
            onShowNonderivedModal={nonDerivedOptions}
            onRemove={removeSection}
            termModalRef={termModalRef}
          />
        );
      })}

      <Modal
        ref={termModalRef}
        size="md"
        component={<TermModal term={null} onClose={() => {}} />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

export default GoalList;
