/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */

import {
  CONTENTTYPES,
  CUSTOMTYPES,
  LLINKID_GOAL_TYPES,
  excludeMandatoryResources,
  flattenAndExtractCurriculaItems,
  getChildren,
  getHighDemarcations,
  getStudyProgrammeTypeNameForGrade,
  translateStudyProgrammesToString,
} from '@utils/curriculumHelper';
import {
  filterByApplicability,
  filterByOrgs,
  filterBySchoolyear,
  filterBySourceOrNonDerivedBasePlan,
  filterCustomCurriculaWithFilters,
  filterByCustomCurriculaGroup,
  filterByCustId,
} from '@utils/filters';
import {
  cloneWithJson,
  mapTo$$Expanded,
  objectMap,
  renameChildrenToItems,
  stripHTML,
  validityPeriodToString,
} from '@utils/utils';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { last, sortBy, flatten, findIndex } from 'lodash-es';

import { logAndCaptureException } from '@utils/logAndCaptureException';
import { defaultTitle } from '../../constants/sectionConstants';
import { creatorType } from '../../constants/creatorType';
// import { diffString, diff } from 'json-diff';

export function groupPointers(pointerList, mergeType) {
  if (!pointerList || !pointerList.length) {
    return [];
  }

  const title = mergeType === 'SCHOOL' ? 'School' : 'Leerplancommissie';
  const icon = mergeType === 'SCHOOL' ? '/icon_school.svg' : '/icon_curriculum.svg';
  return [{ icon, title, pointers: pointerList }];
}

function getRelationFromList(relationships, sourceList) {
  if (!sourceList || !relationships) return [];
  return relationships.reduce((items, relationship) => {
    const found = sourceList.find((res) => res.$$meta.permalink === relationship.from.href);
    if (found) {
      items.push({ ...found, strength: relationship.strength });
    }
    return items;
  }, []);
}

export function buildHeaderInfo(vm, edPointers, edComponents) {
  const viewModel = { ...vm };
  viewModel.generalLevel = {
    ...viewModel.generalLevel,
    principles: viewModel.generalLevel.children,
    children: undefined,
  };
  const curriculumRelations =
    viewModel.$$relationsTo?.filter((res) => res.relationtype === 'REQUIRES') || [];

  const allEducationalPointers = getRelationFromList(curriculumRelations, edPointers);
  viewModel.educationalPointers = groupPointers(allEducationalPointers);
  viewModel.educationalComponents = getRelationFromList(curriculumRelations, edComponents);
  return viewModel;
}

function getStaticPartOfItem(item, extractedCurriculum) {
  const children = getChildren(item, extractedCurriculum);
  const staticPart = children?.find((e) => e.type === CONTENTTYPES.staticpart); /// LLINKID_GOAL_LISTS have a LLINKID_STATIC_PART. LLINKID_GOAL_SECTIONS might just have a child paragraph, which is also considered 'static part'??
  if (staticPart) {
    return getChildren(staticPart, extractedCurriculum)?.find(
      (e) => e.type === CONTENTTYPES.staticpartParagraph
    );
  }
  return children?.find((e) => e.type === CONTENTTYPES.staticpartParagraph);
}

function buildGoals(goal, extractedCurriculum, edPointers, educationalActivityTypes) {
  const highDemarcations = getHighDemarcations(goal, extractedCurriculum);

  const goalRequires = goal.$$relationsTo?.filter(
    (relationship) => relationship.relationtype === 'REQUIRES'
  );

  const goalEDARelations = [
    ...new Set(
      (goal.$$relationsFrom &&
        goal.$$relationsFrom
          .filter(
            (relation) =>
              relation.relationtype === 'REFERENCES' ||
              relation.relationtype === 'SUGGESTED_EDUCATIONAL_ACTIVITY'
          )
          .map((relation) => relation.to.href)) ||
        []
    ),
  ];

  const edActivityTypes = goalEDARelations.reduce((result, relationHref) => {
    const link = educationalActivityTypes.find((e) => e.$$meta.permalink === relationHref);
    if (link) result.push(link);
    return result;
  }, []);

  const pointers = getRelationFromList(goalRequires, edPointers);
  const educationalPointers = groupPointers(pointers);

  return { ...goal, educationalPointers, edActivityTypes, highDemarcations };
}

function sortReadOrder(a, b) {
  const aRo = a.$$readOrder || a.readOrder;
  const bRo = b.$$readOrder || b.readOrder;
  return aRo - bRo;
}

function buildGoalSubItemsTree(child, extractedCurriculum, parentExcluded) {
  const { childrenHrefs, ...cleanChild } = child;
  const subItems = [];
  const nestedChildren = getChildren(child, extractedCurriculum);
  /**
   * the excluded status of a concretization is simply based on the parent.
   * it can not be modified, since a concretization is 'locked' (readonly). it has no buttons to include.
   */
  const excluded = child.type === CONTENTTYPES.concretization ? parentExcluded : child.excluded;
  nestedChildren?.forEach((nestedChild) => {
    subItems.push(buildGoalSubItemsTree(nestedChild, extractedCurriculum, excluded));
  });
  return { ...cleanChild, subItems, excluded };
}

function buildGoalTree(goal, extractedCurriculum) {
  const { childrenHrefs, ...cleanGoal } = goal;
  const goals = [];
  const subItems = [];

  const children = getChildren(goal, extractedCurriculum);

  children?.forEach((child) => {
    if (child.type === CONTENTTYPES.goal || child.type === CUSTOMTYPES.goal) {
      goals.push(buildGoalTree(child, extractedCurriculum));
    } else if (
      child.type === CONTENTTYPES.tip ||
      child.type === CONTENTTYPES.goalExplanation ||
      child.type === CONTENTTYPES.goalExtra ||
      child.type === CONTENTTYPES.initialSituation ||
      child.type === CONTENTTYPES.concretization
    ) {
      subItems.push(buildGoalSubItemsTree(child, extractedCurriculum, goal.excluded));
    }
  });

  return {
    ...cleanGoal,
    goals,
    subItems,
  };
}

function getConcordance(item, extractedCurriculum) {
  const children = getChildren(item, extractedCurriculum);
  const concordance = children?.find((e) => e.type === CONTENTTYPES.concordance);
  return concordance;
}

function buildSectionTree(goalSection, extractedCurriculum) {
  const { childrenHrefs, ...cleanGoalSection } = goalSection;
  const goals =
    getChildren(goalSection, extractedCurriculum)
      ?.filter((e) => e.type === CONTENTTYPES.goal || e.type === CUSTOMTYPES.goal)
      .map((e) => buildGoalTree(e, extractedCurriculum)) || [];

  const sections =
    getChildren(goalSection, extractedCurriculum)
      ?.filter((e) => e.type === CONTENTTYPES.goalSection || e.type === CUSTOMTYPES.section)
      .map((e) => buildSectionTree(e, extractedCurriculum)) || [];

  const subItems =
    getChildren(goalSection, extractedCurriculum)
      ?.filter((e) => e.type === CONTENTTYPES.concretization)
      .map((e) => buildGoalSubItemsTree(e, extractedCurriculum)) || [];

  const concordance = getConcordance(goalSection, extractedCurriculum);

  return {
    ...cleanGoalSection,
    goals,
    sections,
    subItems,
    concordance,
  };
}

function getCollapsedState(section) {
  // let collapsed;
  // // if (school && userService.vm.privateState) {
  // //   collapsed = getCollapsedStateFromPrivateState(section.key, school, curr);
  // // }

  // if (collapsed != null) return collapsed;

  if (section.importance === 'VERY_LOW' || section.importance === 'LOW') return true;

  return false;
}

function buildSection(goalSection, extractedCurriculum, edPointers, mainStaticPart) {
  const staticpart = getStaticPartOfItem(goalSection, extractedCurriculum) || mainStaticPart;
  const collapsed = getCollapsedState(goalSection);

  const sectionRequires = goalSection.$$relationsTo?.filter(
    (relationship) => relationship.relationtype === 'REQUIRES'
  );

  // extract title and description
  let { title } = goalSection;
  const { description, ...restOfGoalSection } = goalSection;
  let placeholder;
  if (goalSection.$$meta.permalink.includes('/customitems/')) {
    placeholder = defaultTitle;
    if (description !== defaultTitle) {
      title = description;
    }
  }

  const pointers = getRelationFromList(sectionRequires, edPointers);
  const educationalPointers = groupPointers(pointers);
  return {
    ...restOfGoalSection,
    title,
    placeholder,
    staticpart,
    collapsed,
    educationalPointers,
  };
}

export function buildGoalListItems(extractedCurriculum, edPointers, educationalActivityTypes) {
  const newItems = {};
  // we need to get the mainStaticPart from the goalList, for the sections
  const goalList = Object.values(extractedCurriculum).find(
    (res) => res.type === CONTENTTYPES.goalList || res.type === CUSTOMTYPES.goalList
  );
  const mainStaticPart = getStaticPartOfItem(goalList, extractedCurriculum);

  const concordance = getConcordance(goalList, extractedCurriculum);

  Object.keys(extractedCurriculum).forEach((href) => {
    const curItem = extractedCurriculum[href];
    if (curItem.type === CONTENTTYPES.goalList) {
      const staticpart = getStaticPartOfItem(curItem, extractedCurriculum);
      newItems[href] = { ...curItem, staticpart, concordance };
    } else if (curItem.type === CONTENTTYPES.goal || curItem.type === CUSTOMTYPES.goal) {
      newItems[href] = buildGoals(
        curItem,
        extractedCurriculum,
        edPointers,
        educationalActivityTypes
      );
    } else if (curItem.type === CONTENTTYPES.concretization) {
      newItems[href] = { ...curItem, locked: true };
    } else if (curItem.type === CONTENTTYPES.goalSection || curItem.type === CUSTOMTYPES.section) {
      newItems[href] = buildSection(curItem, extractedCurriculum, edPointers, mainStaticPart);
    } else {
      newItems[href] = curItem;
    }
  });
  return newItems;
}

const RICH_TEXT_TYPES = [
  'SECTION',
  'PARAGRAPH',
  'IMAGE',
  'IMAGE_GROUP',
  'SUMMARY',
  'LEGAL',
  'QUOTE',
  'VIDEO',
  'REFERENCE_GROUP',
  'ATTACHMENTS_GROUP',
  'TERM',
];

function buildChilds(resourceList, item) {
  const sortedRelations = item.$$relationsTo.sort(
    (a, b) => a.$$expanded.readorder - b.$$expanded.readorder
  );
  item.items = sortedRelations.reduce((childList, relation) => {
    let foundItem = resourceList.find((res) => res.href === relation.$$expanded.from.href);
    if (foundItem) {
      foundItem = foundItem.$$expanded || foundItem;
      if (RICH_TEXT_TYPES.includes(foundItem.type)) {
        childList.push(foundItem);
      }
    }
    return childList;
  }, []);

  item.items.forEach((child) => buildChilds(resourceList, child));
}

function buildRichTextTree(resourceList, root) {
  if (root) {
    buildChilds(resourceList, root.$$expanded || root);
    return root.$$expanded || root;
  }
  return {};
}

export function buildGoalListTab(extractedCurriculum) {
  const goalList = {
    ...Object.values(extractedCurriculum).find(
      (res) => res.type === CONTENTTYPES.goalList || res.type === CUSTOMTYPES.goalList
    ),
  };

  const goals = getChildren(goalList, extractedCurriculum)
    .filter((e) => e.type === CONTENTTYPES.goal || e.type === CUSTOMTYPES.goal)
    .map((g) => buildGoalTree(g, extractedCurriculum));

  const sections = getChildren(goalList, extractedCurriculum)
    .filter((e) => e.type === CONTENTTYPES.goalSection || e.type === CUSTOMTYPES.section)
    .map((g) => buildSectionTree(g, extractedCurriculum));

  const { childrenHrefs, ...goalListClean } = goalList;
  return { ...goalListClean, goals, sections };
}

export function buildIntroductionTab(introduction) {
  const intro = cloneWithJson(introduction);
  const tabType = CONTENTTYPES.tabs.inleiding;

  return buildRichTextTree(
    mapTo$$Expanded(intro),
    intro.find((res) => res.type === tabType)
  );
}

export function buildRichTextTab(curriculum, type) {
  const tabType = CONTENTTYPES.tabs[type];

  return renameChildrenToItems(curriculum.children.find((res) => res.type === tabType));
}

export function getGradeFromCurriculum(curriculum, allPrograms) {
  if (!curriculum || !allPrograms?.length) return;

  const [studyProgram] = curriculum.applicability.studyProgrammes;
  const fullStudyProgram = allPrograms.find((e) => e.$$meta.permalink === studyProgram.href);
  return fullStudyProgram.$$grade;
}

function annotationsForCustCur(custCurInOrder, key, returnObj, allAnnotations) {
  if (!custCurInOrder.length) {
    returnObj[key] = [];
  }

  const customCurricula = custCurInOrder;

  returnObj[key] = cloneWithJson(
    customCurricula.map((customCur) => {
      const annotations = allAnnotations.filter(
        (a) => a.curriculum.href === customCur.$$meta.permalink
      );
      return { ...customCur, annotations };
    })
  );
}

function getLayeredAnnotations(custCurInOrderObj, allAnnotations) {
  const returnObj = {};

  for (const key of Object.keys(custCurInOrderObj)) {
    const custCurInOrder = custCurInOrderObj[key];
    if (custCurInOrder.length) {
      annotationsForCustCur(custCurInOrder, key, returnObj, allAnnotations);
    }
  }
  if (Object.keys(returnObj).length) return returnObj;
  return null;
}

export function getItemsFromReferences(layeredAnnotationsObj, allCustomitems, allGoals) {
  const referencedItems = {};
  for (const key of Object.keys(layeredAnnotationsObj)) {
    const layeredAnnotations = layeredAnnotationsObj[key];

    const layeredReferences = layeredAnnotations.map((layer) =>
      layer.annotations.filter((annotation) => annotation.type === CUSTOMTYPES.goalReference)
    );
    for (const layer of layeredReferences) {
      for (const annotation of layer) {
        const itemsSource = annotation.target.href.startsWith(
          '/llinkid/customcurriculum/customitems'
        )
          ? allCustomitems
          : allGoals; // where to find the referenced item

        const item = itemsSource[getKeyFromHref(annotation.target.href)];
        if (item) {
          referencedItems[annotation.target.href] = { ...item };
        }
      }
    }
  }
  return referencedItems;
}

function sortCustomCurriculas(customCurriculas, orgs, custCurId) {
  const newCustomCurriculas = customCurriculas.map((elem) => {
    const org = orgs.find((o) => o.href === elem.creator.href);
    let priority;
    if (org.creatorType === creatorType.school) {
      priority = 1;
    } else if (org.creatorType === creatorType.team) {
      priority = 2;
    } else {
      priority = 3;
    }
    return { ...elem, priority, creator: { ...elem.creator, type: org.creatorType } };
  });

  newCustomCurriculas.sort((a, b) => a.priority - b.priority);

  // /filter our order object to only have the relevant keys/items.
  return newCustomCurriculas.slice(0, findIndex(newCustomCurriculas, { key: custCurId }) + 1);
}

/**
 * when you are a director your getOrgs has all users/persons in there for which you can see things.
 * this makes that some customcur have 5 person layers. so we have to throw those away and only keep one of the lowest layer (could be team also, but less likely)
 * TODO: check if this would be better to refactor getOrgs instead.
 */
function filterLayerSiblings(customCurriculas, customcurricula) {
  const curCreatorType = customCurriculas.find((cc) => cc.key === customcurricula.key)?.creator
    .type;

  return customCurriculas.filter(
    (customCurr) =>
      customCurr.creator.type !== curCreatorType || customCurr.key === customcurricula.key
  );
}

export function getCustomCurriculasInOrder(customCurr, orgs, schoolyear, customCurriculaMap) {
  const retObj = {};
  for (const customCurricula of customCurr) {
    const custKey = customCurricula.key;
    let customCurriculas;

    if (customCurricula.source) {
      const studyProgram =
        customCurricula.applicability &&
        customCurricula.applicability.studyProgrammes &&
        customCurricula.applicability.studyProgrammes[0].href;

      customCurriculas = filterCustomCurriculaWithFilters(Object.values(customCurriculaMap), [
        filterBySourceOrNonDerivedBasePlan(customCurricula.source.href),
        filterByApplicability([studyProgram]),
        filterByOrgs(orgs),
        filterBySchoolyear(schoolyear),
      ]);
    } else {
      customCurriculas = [customCurricula];
    }

    customCurriculas = sortCustomCurriculas(customCurriculas, orgs, custKey);

    customCurriculas = filterLayerSiblings(customCurriculas, customCurricula);

    retObj[custKey] = customCurriculas;
  }
  return retObj;
}

export function getLayeredAnnotationsObj({
  customCurricula,
  currentSchoolyear,
  orgs,
  annotationsMap,
  customCurriculaMap,
}) {
  if (!customCurricula || !customCurricula.length) return;
  const allAnnotations = Object.values(annotationsMap);
  const customCurriculasInOrderObj = getCustomCurriculasInOrder(
    customCurricula,
    orgs,
    currentSchoolyear,
    customCurriculaMap
  );
  const layeredAnnotationsObj = getLayeredAnnotations(customCurriculasInOrderObj, allAnnotations);

  return layeredAnnotationsObj;
}

export function triggerSelectedItems(entities) {
  const newEntities = { ...entities };
  const hide = {
    LLINKID_GOAL: true,
    LLINKID_PEDAGOGICAL_TIP: true,
    LLINKID_INITIAL_SITUATION: true,
    LLINKID_GOAL_EXPLANATION: true,
    LLINKID_EXTRA_GOAL_INFORMATION: true,
  };
  const types = [
    CONTENTTYPES.goal,
    CUSTOMTYPES.goal,
    CONTENTTYPES.tip,
    CONTENTTYPES.initialSituation,
    CONTENTTYPES.goalExplanation,
    CONTENTTYPES.goalExtra,
  ];
  const itemsToHide = Object.values(newEntities).filter(
    (item) => !item.mandatory && item.excluded && types.includes(item.type)
  );

  itemsToHide.forEach((item) => {
    newEntities[item.href] = { ...newEntities[item.href], hidden: hide[item.type] === true };
  });
  return newEntities;
}

function addAnnotationHashToDict(annotation, hash, hashesDictionary) {
  // /fill the dict with the annotation and possibly the translated goal. hash result for both is the same, but it's added for easy lookup of hash.
  hashesDictionary[annotation.$$meta.permalink] = hash;
  if (annotation.annotation) {
    hashesDictionary[annotation.annotation.$$meta.permalink] = hash;
  }
}

function calculateAnnotationHash(annot) {
  const origAnnot = annot.annotation || annot;

  if (origAnnot.type === CUSTOMTYPES.goalList) {
    return CUSTOMTYPES.goalList;
  }
  if (origAnnot.type === CUSTOMTYPES.goalReference || origAnnot.type === CUSTOMTYPES.goalMove) {
    return origAnnot.target.href;
  }
}

function hashAnnotations(layeredCustCurrObj) {
  const hashesDictionary = {};
  for (const key of Object.keys(layeredCustCurrObj)) {
    const layeredCustomCurrs = layeredCustCurrObj[key];

    layeredCustomCurrs.forEach((layer) => {
      layer.annotations.forEach((annotation) => {
        addAnnotationHashToDict(annotation, calculateAnnotationHash(annotation), hashesDictionary);
      });
    });
  }
  return hashesDictionary;
}

function translateCustomCurPointerToActualPointer(allAnnotations, href) {
  const thePointedTo = allAnnotations.find((e) => e.$$meta.permalink === href);
  if (thePointedTo && thePointedTo.target) {
    return thePointedTo.target.href;
  }
}

/**
 * changes the position of the item.
 * a reposition links directly to another annotation, not to a goal for distribution.
 */
function reposition(annotation, layeredAnnotations) {
  const toRepos = flatten(layeredAnnotations.map((layer) => layer.annotations)).find(
    (e) =>
      // (e.annotation && e.annotation.$$meta.permalink === annotation.target.href) ||
      e.$$meta.permalink === annotation.target.href
  );
  if (toRepos) {
    toRepos.reference = annotation.reference;
    toRepos.after = annotation.after;
    toRepos.readOrder = annotation.readOrder;
    toRepos.reposition = annotation; // this is to know the last reposition, for when we use drag and drop. since the after and reference will be translated to goals rather than annotatons later on.
  } else {
    // we should delete this reposition somewhere, but not here. we don't want to delete repositions if some code is buggy.
  }
}

/**
 * applies all repositions and translates the reference and after to actual goal hrefs.
 */
function handleRepositionAndReferenceTranslation(layeredCustCurrObj) {
  // doing the repositions
  // TODO: make this function pure and not modify the layeredCustCurrObj!
  const newlayeredCustCurrObj = cloneWithJson(layeredCustCurrObj);
  for (const key of Object.keys(newlayeredCustCurrObj)) {
    const layeredCustomCurrs = newlayeredCustCurrObj[key];
    // const lcbefore = cloneWithJson(layeredCustomCurrs);

    layeredCustomCurrs.forEach((layer) => {
      layer.annotations
        .filter((o) => o.type === CUSTOMTYPES.reposition)
        .forEach((annotation) => {
          reposition(annotation, layeredCustomCurrs);
        });
    });

    // console.log(diffString(lcbefore, layeredCustomCurrs));

    const allAnnotations = flatten(layeredCustomCurrs.map((layer) => layer.annotations));

    allAnnotations.forEach((annot) => {
      if (annot.type === CUSTOMTYPES.goalReference) {
        // if it is a type REFERENCE
        if (
          annot.reference &&
          annot.reference.href.startsWith('/llinkid') &&
          !annot.reference.href.includes('customitems')
        ) {
          const contentHref = translateCustomCurPointerToActualPointer(
            allAnnotations,
            annot.reference.href
          );
          if (contentHref) {
            annot.reference = { href: contentHref };
          } else {
            console.warn(`failed to find reference: ${annot.reference.href} during translation.`);
          }
        }
      }
    });
  }
  return newlayeredCustCurrObj;
}

function setPartialForFoundationGoals(
  entities,
  layeredCustCurrObj,
  defaultReferencehref,
  allPrograms,
  hashesDictionary
) {
  // /TODO: the goalkeys set should become a goalHashes set, for this to still work with customGoals.
  const goals = {}; // /a set of all goals keys. used to loop over later.
  const newEntities = { ...entities };
  const distributedGoalsCustCurrObj = {}; // /the distributions for each goal, in an object. property is the goalkey.

  for (const key of Object.keys(layeredCustCurrObj)) {
    const customCurrLayered = layeredCustCurrObj[key];
    const editableLayer = last(layeredCustCurrObj[key]); // /the bottom layer is the only one we can edit.
    const goalsFoundInThisLayer = [];
    let multiLayerAnnot = [];
    customCurrLayered.forEach((layer) => {
      const goalsFromAnnotations = layer.annotations.filter(
        (a) =>
          a.type === CUSTOMTYPES.goalReference &&
          (entities[a.target?.href]?.type === CUSTOMTYPES.goal ||
            entities[a.target?.href]?.type === CONTENTTYPES.goal) // with this silly fix, we got an annotation for each. probably should do it somewhere else.
      );

      goalsFromAnnotations.forEach((annotation) => {
        const entity = entities[annotation.target.href];
        let foundationallyEditable = false;
        if (layer === editableLayer) {
          foundationallyEditable = true;
        }
        const goal = {
          ...entity,
          foundationallyEditable,
          annotation,
        };
        newEntities[goal.$$meta.permalink] = goal;
        goals[goal.$$meta.permalink] = goal;
        goalsFoundInThisLayer.push(goal);
      });
      multiLayerAnnot = multiLayerAnnot.concat(layer.annotations);
    });

    distributedGoalsCustCurrObj[key] = {
      studyProgramme: customCurrLayered[0].applicability.studyProgrammes[0].href,
      goals: goalsFoundInThisLayer,
    };
  }

  for (const entitiesGoal of Object.values(goals)) {
    // till here. goalKeys are goals now.
    const sameGoals = [];
    let partialDistribution = false;
    let partialPositioning = false;
    let foundationallyEditable = false;
    const inStudyProgrammes = [];

    for (const key of Object.keys(distributedGoalsCustCurrObj)) {
      const multiLayerAnnot = distributedGoalsCustCurrObj[key];
      /**
       * to find the goal, we want to find the LAST occurence of this goal.
       * It's possible that the goal exists more than once due to distribution on multiple layers
       * we clone the array reference with slice and reverse the order. then find the now first goal.
       * the original order of the goals is preservincg the order of the layers. so finding the last goal is finding the goal in the lowest layer. We only care about the positioning for the lowest level one.
       */
      const goal = multiLayerAnnot.goals
        .slice()
        .reverse()
        .find((g) => g.key === entitiesGoal.key);

      if (goal) {
        sameGoals.push(goal);
        inStudyProgrammes.push(multiLayerAnnot.studyProgramme);
      } else {
        partialDistribution = true;
      }
    }

    foundationallyEditable = sameGoals.some((goal) => goal.foundationallyEditable === true); // as long as one of the samegoals is on this layer, we can edit something (ie: remove distribution)

    partialPositioning = !sameGoals.every(
      (goal) =>
        sameGoals[0].reference === goal.reference ||
        (sameGoals[0].reference != null &&
          goal.reference != null &&
          hashesDictionary[sameGoals[0].reference.href] === hashesDictionary[goal.reference.href])
    );

    const newPropsForGoal = {
      annotation: { ...newEntities[entitiesGoal.$$meta.permalink].annotation },
    };
    if (partialPositioning) {
      if (defaultReferencehref === null) {
        newPropsForGoal.annotation.reference = null;
      } else {
        newPropsForGoal.annotation.reference = { href: defaultReferencehref };
      }
    } else if (sameGoals[0].annotation.reference) {
      // this else only makes sense in case the newPropsForGoal is not actually any of the samegoals which can happen if a distribution has happened on multiple layers. in that specific case we have to set the reference and after.
      newPropsForGoal.annotation.reference = { ...sameGoals[0].annotation.reference };
    }

    newPropsForGoal.foundationallyEditable = foundationallyEditable;
    newPropsForGoal.partialPositioning = partialPositioning;
    newPropsForGoal.partialDistribution = partialDistribution;
    newPropsForGoal.partialDistributionStudyProgrammes = inStudyProgrammes;
    newPropsForGoal.partialDescription =
      newEntities[entitiesGoal.$$meta.permalink].partialDescription || ''; // this might be set somewhere else before.

    if (newPropsForGoal.partialDistribution) {
      newPropsForGoal.partialDescription += 'Verdeeld in ';
      newPropsForGoal.partialDescription += `${translateStudyProgrammesToString(
        newPropsForGoal.partialDistributionStudyProgrammes,
        allPrograms
      )}.`;
    }

    if (newPropsForGoal.partialPositioning) {
      newPropsForGoal.partialDescription += 'Leerplandoel heeft verschillende posities.';
    }

    newPropsForGoal.readOrder = newPropsForGoal.annotation.readOrder;

    newEntities[entitiesGoal.$$meta.permalink] = {
      ...newEntities[entitiesGoal.$$meta.permalink],
      ...newPropsForGoal,
    };
  }
  return newEntities;
}

function handleFoundationGoals(entities, layeredCustCurrObj, allPrograms, hashesDictionary) {
  const goalList = Object.values(entities).find((item) => item.type === CONTENTTYPES.goalList);

  const permalink = (goalList && goalList.$$meta.permalink) || null;
  return setPartialForFoundationGoals(
    entities,
    layeredCustCurrObj,
    permalink,
    allPrograms,
    hashesDictionary
  );
}

function includeOrTryExcludeItem(item, annotation, locking) {
  const newItem = { ...item } || {};

  if (!newItem.locked) {
    if (newItem.mandatory) {
      newItem.excluded = false; // /do a reset in case a mandatory would ever get excluded somehow.
    } else if (annotation.type === CUSTOMTYPES.inclusion) {
      newItem.excluded = false;
      newItem.locked = locking; // /makes that a team cant undo a school choice.
    } else if (annotation.type === CUSTOMTYPES.exclusion) {
      newItem.excluded = true;
      newItem.locked = locking;
    }
  }

  return newItem;
}

function includeOrTryExcludeItems(entities, layeredCustCurrObj, allPrograms) {
  const targetItems = {};
  const newEntities = { ...entities };
  for (const key of Object.keys(layeredCustCurrObj)) {
    const layeredCustomCurrs = layeredCustCurrObj[key];

    /**
     * Inclusions and exclusions are the last ones to be added, because there can be an inclusion of an annotation and said annotation needs
     * to be added first.
     */
    layeredCustomCurrs.forEach((annotGroup, index) => {
      const lockedLayer = index !== layeredCustomCurrs.length - 1;

      annotGroup.annotations.forEach((annot) => {
        if (annot.type === CUSTOMTYPES.exclusion || annot.type === CUSTOMTYPES.inclusion) {
          const targetGoal = targetItems[annot.target.href] || newEntities[annot.target.href];
          if (targetGoal) {
            const targetItem = { ...targetGoal };
            if (!targetItem.items) {
              targetItem.items = {};
            }
            targetItem.items[key] = includeOrTryExcludeItem(
              targetItem.items[key],
              annot,
              lockedLayer
            );
            targetItems[targetItem.$$meta.permalink] = targetItem;
          }
        }
      });
    });
  }

  // /set default values for those that don't have any
  for (const targetItem of Object.values(targetItems)) {
    for (const key of Object.keys(layeredCustCurrObj)) {
      const studyProgramme = layeredCustCurrObj[key][0].applicability.studyProgrammes[0].href;
      targetItem.items[key] = {
        excluded: targetItem.excluded,
        locked: false,
        ...targetItem.items[key], // overwrite the defaults set in the two lines above, if they were already defined.
        studyProgramme,
      };
    }
  }

  // console.log(targetItems);

  for (const targetItem of Object.values(targetItems)) {
    const items = Object.keys(targetItem.items).map((key) => targetItem.items[key]);
    const locked = items.map((i) => i.locked).every((i) => i);
    const excluded = items.map((i) => i.excluded).every((i) => i);
    const partialInclusion = !items
      .map((i) => i.excluded)
      .every((value, i, array) => value === array[0]);
    const partialInclusionStudyProgrammes = items
      .filter((e) => e.excluded === false)
      .map((i) => i.studyProgramme);
    let partialDescription = targetItem.partialDescription
      ? `${targetItem.partialDescription}<br />`
      : ''; // /this might be set somewhere else before.
    if (partialInclusion) {
      partialDescription += `Opgenomen in ${translateStudyProgrammesToString(
        partialInclusionStudyProgrammes,
        allPrograms
      )}.`;
    }

    newEntities[targetItem.href] = {
      ...newEntities[targetItem.href],
      locked,
      excluded,
      partialInclusion,
      partialDescription,
    };
  }
  return newEntities;
}

function createPlaceHolderSection() {
  return {
    $$meta: {
      type: 'FOUNDATIONALPLACEHOLDER',
      permalink: '/content/FOUNDATIONALPLACEHOLDER',
    },
    href: '/content/FOUNDATIONALPLACEHOLDER',
    title: 'Gemeenschappelijke doelen',
    type: CONTENTTYPES.goalSection,
    goals: [],
  };
}

// function addItemAfter(list, itemToAdd) {
//   list.push(itemToAdd);
//   list = list.sort(sortReadOrder);
// }

export function addDraggable(item) {
  let itemWithDraggable = item;
  const draggable = item.type === CONTENTTYPES.goal || item.type === CUSTOMTYPES.goal; // added/distributed goals are draggable;
  if (draggable) itemWithDraggable = { ...item, draggable }; // don't add the draggable prop on other types of items.
  return itemWithDraggable;
}

function addChildHref(parent, item, entities) {
  const newChildrenUnsorted = getChildren(
    { childrenHrefs: [...(parent.childrenHrefs || []), item.href] },
    entities
  );
  const sortedChildren = [...newChildrenUnsorted].sort(sortReadOrder);
  const sortedChildrenHrefs = sortedChildren.map((e) => e.href);

  return {
    ...parent,
    childrenHrefs: sortedChildrenHrefs,
  };
}

/**
 * Iterates on the viewModel tree (goalList) and adds the annotation given.
 * @param goalWithAnnotation
 * @param entities
 * @param goalList
 */
function insertAnnotation(goalWithAnnotation, entities, goalList) {
  const newEntities = { ...entities };

  if (
    goalList.type !== CUSTOMTYPES.goalList &&
    goalList.$$meta.permalink === goalWithAnnotation.annotation.reference.href // the fake section is only used for derived curricula.
  ) {
    const lastSection = last(
      getChildren(goalList, newEntities).filter((e) => e.type === CONTENTTYPES.goalSection)
    );

    newEntities[lastSection.href] = addChildHref(
      newEntities[lastSection.href],
      goalWithAnnotation,
      entities
    );
  } else {
    // we have a custom goallist on our hands? OR the item is under some other section that isn't the goallist.
    const annotationReference =
      goalWithAnnotation.annotation.reference && goalWithAnnotation.annotation.reference.href;

    let toAnnotate = Object.values(newEntities).find(
      (item) =>
        item.$$meta.permalink === annotationReference || // / compare to null if reference is null, compare to href if there is one.
        (item.annotation && item.annotation.$$meta.permalink === annotationReference)
    ); // will this always work?? can there be a case where the reference.href simply isn't found in a merged curricula? if this ever becomes an issue. An idea would be to translate the reference and afters to actual goal hrefs, instead of annotation hrefs (somewhere higher up in the chain, for example after the repositions). or it could be stored as such in the APIs.
    // however when starting with creating custom goals from scratch this whole thing will become 10 times harder.

    if (!toAnnotate) {
      console.warn(`Item to annotate not found. href:${annotationReference}`);
      toAnnotate = toAnnotate || {};
    }

    if (toAnnotate.type === CONTENTTYPES.goal || toAnnotate.type === CUSTOMTYPES.goal) {
      // debugger; // i think this part can never be true anymore?
      logAndCaptureException('unexpected code still happening: annotateGoal');
      // annotateGoal(toAnnotate, goalWithAnnotation, LISTS[goalWithAnnotation.type]);
    } else if (
      toAnnotate.type === CONTENTTYPES.goalSection ||
      toAnnotate.type === CUSTOMTYPES.goalList ||
      toAnnotate.type === CUSTOMTYPES.section
    ) {
      newEntities[toAnnotate.href] = addChildHref(toAnnotate, goalWithAnnotation, entities);
    } else {
      logAndCaptureException(`Annotation parent not found:${JSON.stringify(goalWithAnnotation)}`);
    }
  }
  return newEntities;
}

export function sortByIdentifier(goals) {
  const sortedGoals = sortBy(
    [...goals],
    [
      (o) => {
        const indexOfDigit = o.identifier.search(/\d/);
        return o.identifier.substring(0, indexOfDigit);
      },
      (o) => {
        const indexOfDigit = o.identifier.search(/\d/);
        return parseFloat(o.identifier.substring(indexOfDigit), 10);
      },
    ]
  );
  return sortedGoals;
}

function sortChildrenAlphabetically(parent, entities) {
  // we want the goals under the fake section to be ordered alphabetically by code since there is no actual order in the placeholder
  const placeholderChildren = getChildren(parent, entities);
  if (!placeholderChildren?.length) {
    return entities;
  }
  const newEntities = { ...entities };
  const sortedPlaceholderChildren = sortByIdentifier(placeholderChildren);

  // overwrite the existing readOrder to keep the readorder consistent.
  sortedPlaceholderChildren.forEach((child, index) => {
    newEntities[child.href] = { ...newEntities[child.href], readOrder: index };
  });

  newEntities[parent.href] = {
    ...newEntities[parent.href],
    childrenHrefs: sortedPlaceholderChildren.map((e) => e.href),
  };
  return newEntities;
}

function insertAnnotations(entities) {
  let newEntities = { ...entities };
  let goalList = {
    ...Object.values(newEntities).find(
      (item) => item.type === CONTENTTYPES.goalList || item.type === CUSTOMTYPES.goalList
    ),
  };

  const goalsWithAnnotations = Object.values(entities).filter((e) => e.annotation);
  let placeholderHref;
  if (goalsWithAnnotations.length && goalList.type !== CUSTOMTYPES.goalList) {
    /**
     * If an item doesn't have an after (not repositioned) but still connected to the goalList, then we have to create a fake
     * section in the bottom to show the item in some way.
     */
    const readOrder = last(getChildren(goalList, entities)).readOrder + 1;
    const placeholder = { ...createPlaceHolderSection(), readOrder };
    placeholderHref = placeholder.$$meta.permalink;
    newEntities[placeholderHref] = placeholder;
    goalList = { ...goalList, childrenHrefs: [...goalList.childrenHrefs, placeholder.href] };
    newEntities[goalList.href] = goalList;
  }

  // const sortedReferenceAnnot = sortReferences(distributedItems); // this shouldn't matter anymore with the flat list we have now.

  goalsWithAnnotations.forEach((item) => {
    newEntities = insertAnnotation(item, newEntities, goalList);
  });

  if (placeholderHref) {
    newEntities = sortChildrenAlphabetically(newEntities[placeholderHref], newEntities);
  }

  return newEntities;
}

function addAnnotationToItems(entities, layeredCustCurrObj) {
  const newEntities = { ...entities };

  for (const key of Object.keys(layeredCustCurrObj)) {
    const customCurrLayered = layeredCustCurrObj[key];
    customCurrLayered.forEach((layer) => {
      const goalsFromAnnotations = layer.annotations.filter(
        (a) =>
          a.type === CUSTOMTYPES.goalReference &&
          (entities[a.target?.href]?.type === CONTENTTYPES.goal ||
            entities[a.target?.href]?.type === CUSTOMTYPES.goal ||
            entities[a.target?.href]?.type === CUSTOMTYPES.section) // with this silly fix, we got an annotation for each. probably should do it somewhere else.
      );

      goalsFromAnnotations.forEach((annotation) => {
        const goal = {
          ...entities[annotation.target.href],
          annotation,
          readOrder: annotation.readOrder,
        };
        newEntities[goal.$$meta.permalink] = goal;
      });
    });
  }
  return newEntities;
}

export function buildAnnotations(layeredCustCurrObj, entities, allPrograms) {
  let modifiedEntities = { ...entities };
  // const customOrDistributedItems = getCustomItems(layeredCustCurrObj);

  // const flattenedItems = customOrDistributedItems.reduce(
  //   (allItems, goal) => (allItems = allItems.concat(flatRefrencedCurriculaItems(goal))),
  //   []
  // );

  const hashesDictionary = hashAnnotations(layeredCustCurrObj);

  const layeredCustCurrObjWithRepositions =
    handleRepositionAndReferenceTranslation(layeredCustCurrObj); // modifies the layeredCustCurrObj directly...

  // let modifiedEntities = addAnnotationToSection(entities, layeredCustCurrObjWithRepositions);

  modifiedEntities = addAnnotationToItems(modifiedEntities, layeredCustCurrObjWithRepositions);

  modifiedEntities = handleFoundationGoals(
    modifiedEntities,
    layeredCustCurrObjWithRepositions,
    allPrograms,
    hashesDictionary
  );
  modifiedEntities = includeOrTryExcludeItems(
    modifiedEntities,
    layeredCustCurrObjWithRepositions,
    allPrograms
  ); // we use the flattenedItems, so that we can find tips and subgoals.
  modifiedEntities = insertAnnotations(modifiedEntities);
  return modifiedEntities;
}

export function translatePointers(pointers, allPointers) {
  return pointers.reduce((translatedList, pointer) => {
    if (pointer.href) {
      const foundPointer = allPointers.find((item) => pointer.href === item.$$meta.permalink);
      if (foundPointer) {
        translatedList.push(foundPointer);
      } else {
        console.warn(`educational pointer not found in the /content list: ${pointer.href}`);
      }
    } else {
      // translatedList.push custom pointers here
    }
    return translatedList;
  }, []);
}

function getSubTitle(customCurriculaStack, allOrgs) {
  if (customCurriculaStack.length === 0) {
    return '';
  }

  // the problem we run into is that you can have multiple teams that made a CC for the same base cur.
  // in this case we get all of those teams in the subtitle (as well as their changes).
  // the fix: filter out the teams that have no changes, if the lowest level is a personal cur.
  const filterTeamsWithoutAnnotations =
    last(customCurriculaStack).creator.type === 'TEACHER' &&
    customCurriculaStack.filter((e) => e.creator.type === 'TEAM').length > 1;

  const names = [];
  customCurriculaStack.forEach((cc) => {
    if (filterTeamsWithoutAnnotations && cc.creator.type === 'TEAM' && cc.annotations.length === 0)
      return;
    const org = allOrgs.find((s) => cc.creator.href === s.href);
    if (org) names.push(org.$$displayName);
  });

  return names.reverse().join(' - ');
}

function getValidityDates(customCurricula) {
  if (customCurricula.length === 0) {
    return;
  }

  return customCurricula[customCurricula.length - 1].issued;
}

function getStudyProgram(curricula, allPrograms) {
  if (curricula.length === 0) {
    return null;
  }
  const program = allPrograms.find(
    (p) => curricula[0].applicability.studyProgrammes[0].href === p.$$meta.permalink
  );
  return program.title;
}
function getCurriculumType(curriculas) {
  if (curriculas.length === 0) {
    return 'PLAN';
  }
  return curriculas[curriculas.length - 1].creator.type;
}

export function getTitleObjects(
  curTitle,
  customCurriculasInOrder,
  curriculaCount,
  allPrograms,
  allOrgs,
  grade
) {
  let annotTitle = '';
  if (customCurriculasInOrder[Object.keys(customCurriculasInOrder)[0]].length) {
    // in case the customcur in URL doesnt exist or isn't of this user
    annotTitle = last(customCurriculasInOrder[Object.keys(customCurriculasInOrder)[0]]).title;
  }

  const title = curTitle + (curTitle && annotTitle ? ' - ' : '') + annotTitle;

  const curriculumType = getCurriculumType(customCurriculasInOrder); // /no mixed typing here, so we just send the first key. KISS
  const subTitle = getSubTitle(customCurriculasInOrder, allOrgs);
  const validityPeriodString = validityPeriodToString(getValidityDates(customCurriculasInOrder));
  const studyProgram =
    curriculaCount > 1
      ? `${curriculaCount} ${getStudyProgrammeTypeNameForGrade(grade)}`
      : getStudyProgram(customCurriculasInOrder, allPrograms); // TODO also pass the studyprogrammeGroups in this FN for smart naming.
  return { title, curriculumType, subTitle, validityPeriodString, studyProgram };
}

export function getGoalsToLoad(goalsToLoadHrefs, allGoalsInState, goalsToLoadStatuses, preview) {
  const goalsToLoad = new Set(goalsToLoadHrefs);
  if (!preview) {
    // if we're not in preview mode, we can delete any goals that we already have found in the state.
    [...goalsToLoad].forEach((href) => {
      if (allGoalsInState[getKeyFromHref(href)]) {
        goalsToLoad.delete(href);
      }
    });
  }

  [...goalsToLoad].forEach((href) => {
    const toLoadStatus = goalsToLoadStatuses[getKeyFromHref(href)];
    if (toLoadStatus && toLoadStatus.isLoaded === false) {
      goalsToLoad.delete(href); // delete goals that are already "scheduled" to be loaded.
    }
  });
  // console.timeEnd('selectExtraGoalsToLoad');
  if (goalsToLoad.size) console.log('goalsToLoad', [...goalsToLoad]);
  return [...goalsToLoad];
}

export function compareGoalDescription(g1, g2) {
  if (g1?.description && g2?.description) {
    return stripHTML(g1.description) === stripHTML(g2.description);
  }
  return false;
}

/**
 * add params to the custom goal that we expect to exist in a regular LLINKID_GOAL
 * @param {} customGoal
 * @returns
 */
export function transformCustomGoalToGoalFormat(customGoal) {
  return {
    llinkidGoalType: LLINKID_GOAL_TYPES.CUSTOM,
    attitudinal: false,
    foundational: false,
    deepening: false,
    mandatory: true,
    href: customGoal.$$meta.permalink,
    ...customGoal,
  };
}

/**
 * this function is used to build goals that are not in a curriculum.
 * for example, when selecting goals from a plan, or when selecting goals from a selection.
 * sometimes you just have to build a goal, without the context of a curriculum.
 * Ideally, this function should be used for building all goals, and the building of a curriculum should be simplified.
 */
export function buildGoalsFromExtractedCurriculumNodes(
  goals,
  edPointers,
  educationalActivityTypes
) {
  let extractedCurriculumNodes = {};

  goals.forEach((item) => {
    // const itemWithDraggable = addDraggable(item);
    const extract = flattenAndExtractCurriculaItems(item);
    extractedCurriculumNodes = {
      ...extractedCurriculumNodes,
      ...extract,
    };
  });

  extractedCurriculumNodes = objectMap(extractedCurriculumNodes, (z) => {
    if (z.type === CONTENTTYPES.goal || z.type === CUSTOMTYPES.goal) {
      return buildGoals(z, extractedCurriculumNodes, edPointers, educationalActivityTypes);
    }
    return z;
  });

  extractedCurriculumNodes = excludeMandatoryResources(extractedCurriculumNodes);

  const builtGoals = goals.map((e) => ({
    ...buildGoalTree(extractedCurriculumNodes[e.href], extractedCurriculumNodes),
  }));

  return builtGoals;
}

function getCustomCurricula({ allCustomCurriculas, customCurriculaGroup, studyPrograms, custIds }) {
  const filters = [];
  if (customCurriculaGroup) filters.push(filterByCustomCurriculaGroup(customCurriculaGroup));
  if (studyPrograms) filters.push(filterByApplicability(studyPrograms));
  if (custIds) filters.push(filterByCustId(custIds));

  const customCurriculas = filterCustomCurriculaWithFilters(allCustomCurriculas, filters);
  return customCurriculas;
}

export function getCustomCurriculaFromIds(setid, studids, custids, allCustomCurriculas) {
  if (setid && studids?.length) {
    return getCustomCurricula({
      allCustomCurriculas,
      customCurriculaGroup: setid,
      studyPrograms: studids,
    });
  }
  if (custids?.length) {
    return getCustomCurricula({ allCustomCurriculas, custIds: custids });
  }
  return undefined;
}

export function getCustomCurriculaFromSetId(setid, allCustomCurriculas) {
  return getCustomCurricula({ allCustomCurriculas, customCurriculaGroup: setid });
}

export function getCurriculumKeyFromStateOrParams(state, params) {
  return params && params.curriculumKey ? params.curriculumKey : state.curriculum.curriculumKey;
}
