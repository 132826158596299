import dateUtils from '@kathondvla/sri-client/date-utils';

import { createTypedSelector } from '@store/genericHelpers';
import { RootState } from '@store/storeSetup';
import { filterResourcesActiveInSchoolyear, getSchoolyears } from '@utils/utils';
import { filterResourcesActiveOnDate } from '@utils/filterResourcesActiveOnDate';
import { isAfter } from 'date-fns/isAfter';
import { isSameYear } from 'date-fns/isSameYear';
import { isEqual } from 'lodash-es';
import { shallowEqual } from 'react-redux';
import { isPast } from 'date-fns/isPast';
import { isToday } from 'date-fns/isToday';
import settings from '@config/settings';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { creatorType } from '../../constants/creatorType';
import * as positions from '../../constants/positions';
import { Person } from '../../types/personApiTypes';
import { SamClass, SamTeacherGroup } from '../../types/samApiTypes';
import {
  School,
  SchoolData,
  SchoolVM,
  VmClass,
  VmCreator,
  VmSchoolMini,
  VmTeacher,
  VmTeam,
} from './userAndSchoolTypes';

function getAddresses(school: School) {
  const campuses = school.campuses || [];
  const schoolEndDate = school.endDate;
  const activeCampuses = campuses.filter((c) => {
    if (!c.endDate) return true;
    if (schoolEndDate) return schoolEndDate === c.endDate;

    return isAfter(new Date(c.endDate), new Date());
  });
  let sortedCampuses = activeCampuses;

  if (!activeCampuses?.length) {
    const campusesByEndDate = campuses.sort(
      (a, b) =>
        new Date(b.endDate || '2099-01-01').getTime() -
        new Date(a.endDate || '2099-01-01').getTime()
    );
    const [campus] = campusesByEndDate;
    sortedCampuses = campusesByEndDate.filter((c) =>
      isSameYear(new Date(c.endDate || '2099-01-01'), new Date(campus.endDate || '2099-01-01'))
    );
  }
  return sortedCampuses.map((c) => c.physicalLocation);
}

function getClassVM(c: SamClass, active: boolean = true): VmClass {
  const $$displayName = active ? c.$$displayName : `${c.$$displayName} (verwijderd)`;
  return {
    $$displayName,
    href: c.$$meta.permalink,
    $$meta: c.$$meta,
    key: c.key,
    type: c.type,
    startDate: c.startDate,
    endDate: c.endDate,
  };
}

const getTeamVm = (team: SamTeacherGroup): VmTeam => {
  return {
    href: team.$$meta.permalink,
    creatorType: creatorType.team,
    $$displayName: team.$$displayName,
    type: team.type,
    startDate: team.startDate,
    endDate: team.endDate,
    key: team.key,
    $$meta: team.$$meta,
  };
};

export const selectCurrentSchoolHref = (state: RootState) => state.userAndSchools.currentSchoolHref;
export const selectCurrentSchoolData = (state: RootState) =>
  state.userAndSchools.schoolsData[selectCurrentSchoolHref(state)];
export const selectUser = (state: RootState) => state.userAndSchools.user;

/**
 * this selector returns the last day/date where the user was active in the school.
 * this could be because either his responsiblity ended, or the school ended, or both, whichever happened first.
 */
export const selectLastActiveDateForSchools = createTypedSelector(
  [
    (state) => state.userAndSchools.samResponsibilities,
    (state) => state.userAndSchools.schoolsData,
  ],
  (resps, schoolDataMap) => {
    const orgHrefs = Object.keys(schoolDataMap);

    const lastActiveDateMap = Object.fromEntries(
      orgHrefs.map((href) => {
        let userRespEndDate: string | null = null;

        const schoolResps = resps.filter((z) => z.organisation.href === href);
        if (dateUtils.getActiveResources(schoolResps).length === 0) {
          // if there are no active responsibilities, we take the last endDate
          userRespEndDate = schoolResps.reduce((acc: string | null, z) => {
            if (!z.endDate) return acc;
            if (!acc) return z.endDate;
            return isAfter(new Date(z.endDate), new Date(acc)) ? z.endDate : acc;
          }, null);
        }

        let earliestEndDate;
        const { endDate } = schoolDataMap[href].school;
        if (endDate === null && userRespEndDate === null) {
          // If both are null, consider it as open-ended future
          earliestEndDate = null;
        } else if (endDate === null) {
          // If endDate is null (forever in the future), use userRespEndDate
          earliestEndDate = userRespEndDate;
        } else if (userRespEndDate === null) {
          // If userRespEndDate is null (forever in the future), use endDate
          earliestEndDate = endDate;
        } else {
          // If neither is null, compare and pick the earliest (minimum) date
          earliestEndDate =
            new Date(endDate) < new Date(userRespEndDate) ? endDate : userRespEndDate;
        }

        // we remove one day to the endDate because just on the last day the user won't have
        // responsibilities anymore. So we take into account the last day before the responsibility endDate
        if (earliestEndDate) {
          const endDatePlusOne = new Date(earliestEndDate);
          endDatePlusOne.setDate(endDatePlusOne.getDate() - 1);
          return [href, dateUtils.toString(endDatePlusOne) as string];
        }
        return [href, null];
      })
    );
    // console.log('lastActiveDateMap', lastActiveDateMap);
    return lastActiveDateMap;
  }
);

export const selectLastActiveDateForSchool = (state, schoolHref) => {
  return selectLastActiveDateForSchools(state)[schoolHref];
};

const selectSamResponsibilitiesForSchools = createTypedSelector(
  [
    (state) => state.userAndSchools.samResponsibilities,
    (state) => state.userAndSchools.schoolsData,
  ],
  (samResponsibilities, schools) => {
    if (!samResponsibilities || !schools) return {};

    const samRespSchoolMap = Object.fromEntries(
      Object.values(schools).map((school) => {
        const schoolHref = school.school.$$meta.permalink;
        const hrefsForSchool = new Set([
          schoolHref,
          ...school.teacherGroups.map((tg) => tg.$$meta.permalink),
        ]);

        const samResponsibilitiesForSchool = samResponsibilities.filter((resp) =>
          hrefsForSchool.has(resp.organisation.href)
        );

        return [schoolHref, samResponsibilitiesForSchool];
      })
    );
    // console.log('activeSamRespSchoolMap', activeSamRespSchoolMap);
    return samRespSchoolMap;
  }
);

export const selectSchoolyearsForSchool = createTypedSelector(
  [
    (state) => selectCurrentSchoolData(state)?.school?.startDate,
    (state) => selectLastActiveDateForSchool(state, selectCurrentSchoolHref(state)),
    () => new Date().toDateString(),
  ],
  (startDate, endDate, _today) => {
    // we need _today in here to make sure this refreshes every day, because getSchoolyears is not a pure function.
    // it is of utter importance when we are mocking the date of today in tests.
    const schoolyearsForSchool = getSchoolyears(startDate, endDate);
    return schoolyearsForSchool;
  }
);

export const selectSchoolyearFromParamOrCurrent = (
  state: RootState,
  params?: { schoolyear: string }
) =>
  selectSchoolyearsForSchool(state).find(
    (e) => e.key === (params?.schoolyear || state.userAndSchools.currentSchoolyear)
  );

const selectActiveSamResponsibilitiesForSchoolInSchoolyear = createTypedSelector(
  [
    (state) => selectSamResponsibilitiesForSchools(state)?.[selectCurrentSchoolHref(state)],
    (state, params?: { schoolyear: string }) => selectSchoolyearFromParamOrCurrent(state, params),
  ],
  (samResponsibilitiesPerSchool, schoolyear) => {
    if (!samResponsibilitiesPerSchool || samResponsibilitiesPerSchool.length === 0 || !schoolyear) {
      return [];
    }

    const activeSamResponsibilitiesForSchool = filterResourcesActiveInSchoolyear(
      samResponsibilitiesPerSchool,
      schoolyear
    );
    return activeSamResponsibilitiesForSchool;
  }
);

const selectActiveSamResponsibilitiesForSchools = createTypedSelector(
  [
    (state) => selectSamResponsibilitiesForSchools(state),
    (state) => selectLastActiveDateForSchools(state),
  ],
  (samResponsibilitiesPerSchool, lastActiveDateMap) => {
    if (!samResponsibilitiesPerSchool || Object.keys(samResponsibilitiesPerSchool).length === 0) {
      return {};
    }

    const activeSamRespSchoolMap = Object.fromEntries(
      Object.entries(samResponsibilitiesPerSchool).map(
        ([schoolHref, samResponsibilitiesForSchool]) => {
          const activeSamResponsibilitiesForSchool = filterResourcesActiveOnDate(
            samResponsibilitiesForSchool,
            lastActiveDateMap[schoolHref]
          );

          return [schoolHref, activeSamResponsibilitiesForSchool];
        }
      )
    );
    // console.log('activeSamRespSchoolMap', activeSamRespSchoolMap);
    return activeSamRespSchoolMap;
  }
);

export const selectActiveSamResponsibilitiesForSchool = (state: RootState, schoolHref?: string) =>
  selectActiveSamResponsibilitiesForSchools(state)?.[schoolHref || selectCurrentSchoolHref(state)];

export function selectIsDirectorInList(resps, schoolHref) {
  return resps.some(
    (resp) =>
      resp.organisation.href === schoolHref &&
      resp.position &&
      (resp.position.href === positions.directeur ||
        resp.position.href === positions.pedagogischVerantwoordelijke)
  );
}

export function selectIsDirector(resps) {
  return resps.some(
    (resp) =>
      resp.position &&
      (resp.position.href === positions.directeur ||
        resp.position.href === positions.pedagogischVerantwoordelijke)
  );
}

const getTeacherVm = (teacher: Person): VmTeacher => {
  return {
    $$displayName: `${teacher.firstName} ${teacher.lastName}`,
    href: teacher.$$meta.permalink,
    creatorType: creatorType.teacher,
    $$meta: teacher.$$meta,
    key: teacher.key,
    mergedPersons: teacher.mergedPersons,
    firstName: teacher.firstName,
    lastName: teacher.lastName,
  };
};

// const accessedProperties = new Set();

// const removedProperties = new Set([
//   'unusableTeams',
//   'allTeams',
//   'activeTeams',
//   'teams',
//   'teachers',
//   'classes',
//   'activeClasses',
// ]); // 'teams'

// const logUnusedProperties = debounce(() => {
//   const unusedProperties = [
//     '$$displayName',
//     'href',
//     'creatorType',
//     'teachers',
//     '$$addresses',
//     'classes',
//     'teams',
//     'unusableTeams',
//     'allTeams',
//     'activeTeams',
//     'activeClasses',
//     'key',
//     '$$meta',
//     'endDate',
//     'leidraadChoices',
//     'startDate',
//     'studyProgrammeGroups',
//     'studyProgrammes',
//   ].filter((extension) => !accessedProperties.has(extension));
//   console.log('SchoolVM: Unused properties:', unusedProperties);
//   console.log('SchoolVM: Accessed properties:', Array.from(accessedProperties));
// }, 5000);

// // @ts-expect-error not used for now
// const handler = {
//   get(target, prop, receiver) {
//     accessedProperties.add(prop);
//     logUnusedProperties();
//     if (removedProperties.has(prop)) {
//       throw new Error(`Property ${prop} has been removed from the view model.`);
//     }
//     // @ts-expect-error arguments
//     return Reflect.get(...arguments);
//   },
// };

function buildSchoolVM(schoolData: SchoolData) {
  const schoolVM: SchoolVM = {
    $$displayName: schoolData.school.$$displayName,
    href: schoolData.school.$$meta.permalink,
    creatorType: creatorType.school,
    $$addresses: getAddresses(schoolData.school),
    key: schoolData.school.key,
    $$meta: schoolData.school.$$meta,
    endDate: schoolData.school.endDate,
    leidraadChoices: schoolData.leidraadChoices,
    startDate: schoolData.school.startDate,
    studyProgrammeGroups: schoolData.studyProgrammeGroups,
    studyProgrammes: schoolData.studyProgrammes,
  };
  // return new Proxy(schoolVM, handler); // Object.freeze(schoolVM);
  return schoolVM;
}

const selectAreAllSchoolsLoaded = (state: RootState) => {
  if (!state.userAndSchools.schoolsFetched) return false;
  return Object.values(state.userAndSchools.schoolsData).every(
    (z) =>
      z.responsibilitiesInSchoolFetched === true &&
      z.teachersInSchoolFetched === true &&
      z.studyProgrammeGroupsFetched === true &&
      z.teachersForTeamsFetched === true
  );
};

/**
 * we need to get rid of this schoolVM. the data should be cut up in smaller selectors and used as such.
 */
export const selectSchoolVMs = createTypedSelector(
  [(state) => state.userAndSchools.schoolsData],
  (schools) => {
    // const count = counter++;
    // console.time(`schoolVMsSelector-${count}`);
    const result = Object.values(schools).map((school) => buildSchoolVM(school));
    // console.timeEnd(`schoolVMsSelector-${count}`);
    return result;
  }
);

/**
 * we need to get rid of this schoolVM. the data should be cut up in smaller selectors and used as such.
 */
export const selectSchoolVM = createTypedSelector(
  [(state, params) => state.userAndSchools.schoolsData[params.href]],
  (school) => {
    if (!school) return undefined;
    return buildSchoolVM(school);
  }
);

export const selectCurrentSchool = (state: RootState) =>
  selectSchoolVM(state, { href: selectCurrentSchoolHref(state) });

export const selectPrincipalSchoolHrefs = createTypedSelector(
  [(state) => state.userAndSchools.samResponsibilities, selectLastActiveDateForSchools],
  (samResponsibilities, lastActiveDateMap) => {
    const schoolBossResponsibilities = samResponsibilities.filter(
      (resp) =>
        resp.position &&
        (resp.position.href === positions.directeur ||
          resp.position.href === positions.pedagogischVerantwoordelijke)
    );

    const activeBossResps = schoolBossResponsibilities.filter((z) => {
      return filterResourcesActiveOnDate([z], lastActiveDateMap[z.organisation.href]).length > 0;
    });

    return activeBossResps.map((r) => r.organisation.href);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectUserHref = (state: RootState) => state.userAndSchools.user?.$$meta?.permalink;

export const selectIsDirectorInCurrentSchool = createTypedSelector(
  [selectPrincipalSchoolHrefs, (state) => selectCurrentSchoolHref(state)],
  (principalSchoolHrefs, currentSchoolHref) => {
    return principalSchoolHrefs.includes(currentSchoolHref);
  }
);

export const selectCurrentSchoolyearKey = (state: RootState) =>
  state.userAndSchools.currentSchoolyear;

export const selectCurrentSchoolyear = createTypedSelector(
  [selectSchoolyearsForSchool, (state) => state.userAndSchools.currentSchoolyear],
  ($schoolyears, currentSchoolyear) => {
    return $schoolyears.find((e) => e.key === currentSchoolyear);
  }
);

export const selectInitializedSchoolyearsForSchool = createTypedSelector(
  [selectSchoolyearsForSchool],
  ($schoolyearsForSchool) => {
    return $schoolyearsForSchool.filter((e) => e.initialized);
  }
);

export const selectIsUserLoaded = (state: RootState) => {
  return state.userAndSchools.userFetched;
};

const selectIsUserLoggedIn = (state: RootState) => {
  return state.userAndSchools.userFetched && state.userAndSchools.user;
};

export const selectIsUserNotLoggedIn = (state: RootState) => {
  return state.userAndSchools.userFetched && !state.userAndSchools.user;
};

const selectIsPrivateStateLoaded = (state: RootState) => {
  const { privateState, userFetched } = state.userAndSchools;
  return (
    userFetched && selectUserHref(state) && privateState && Object.keys(privateState).length > 0
  );
};

const isUserAccessingBetweenTargetDates = (initialDate, endDate) => {
  const currentDate = new Date();
  return currentDate > new Date(initialDate) && currentDate < new Date(endDate);
};

export const selectShouldShowWhatIsNewModal = (state: RootState) => {
  const {
    whatIsNewModal: { autoShowIfNeeded, initialDate, endDate, version },
  } = settings;
  // we only autoshow the modal if the user is accessing the applications during the target dates.
  if (
    !autoShowIfNeeded ||
    (autoShowIfNeeded && !isUserAccessingBetweenTargetDates(initialDate, endDate))
  ) {
    return false;
  }
  if (selectIsUserLoggedIn(state) && !selectIsPrivateStateLoaded(state)) {
    return false;
  }
  const skipped = state.userAndSchools.currentModalVersionSkipped;
  if (selectIsUserNotLoggedIn(state) && !skipped) {
    return true;
  }
  const { privateState } = state.userAndSchools;
  return !skipped && privateState?.state?.modals && !privateState.state.modals[version];
};

export const selectIsUserAndSchoolStateInitialized = (state: RootState) => {
  return (
    selectIsUserNotLoggedIn(state) ||
    (selectIsUserLoggedIn(state) && selectAreAllSchoolsLoaded(state))
  );
};

const selectAllTeachers = createTypedSelector(
  [(state) => selectCurrentSchoolData(state)?.teachers],
  (teachers) => {
    return teachers?.map((teacher) => getTeacherVm(teacher));
  }
);

const selectAllTeams = createTypedSelector(
  [(state) => selectCurrentSchoolData(state)?.teacherGroups],
  (teacherGroups) => {
    return teacherGroups?.map((team) => getTeamVm(team));
  }
);

export const selectAllClassesInSchoolyear = createTypedSelector(
  [
    (state) => selectCurrentSchoolData(state)?.classes,
    (state, params?: { schoolyear: string }) => selectSchoolyearFromParamOrCurrent(state, params),
  ],
  (classes, schoolyear): VmClass[] => {
    if (!schoolyear || !classes) return classes?.map((c) => getClassVM(c));

    const activeClasses = filterResourcesActiveInSchoolyear(classes, schoolyear);
    const activeKeys = new Set(activeClasses.map((z) => z.key));
    return classes.map((c) => getClassVM(c, activeKeys.has(c.key)));
  }
);

export const selectActiveClassesInSchoolyear = createTypedSelector(
  [
    (state) => selectCurrentSchoolData(state)?.classes,
    (state, params?: { schoolyear: string }) => selectSchoolyearFromParamOrCurrent(state, params),
  ],
  (classes, schoolyear): VmClass[] => {
    if (!schoolyear) return [];

    const activeClasses = filterResourcesActiveInSchoolyear(classes, schoolyear);
    return activeClasses.map((c) => getClassVM(c, true));
  }
);

export const selectMiniSchoolVM = createTypedSelector([selectCurrentSchoolData], (schoolData) => {
  if (!schoolData) return undefined;
  const schoolVM: VmSchoolMini = {
    $$displayName: schoolData.school.$$displayName,
    href: schoolData.school.$$meta.permalink,
    creatorType: creatorType.school,
    key: schoolData.school.key,
    $$meta: schoolData.school.$$meta,
    startDate: schoolData.school.startDate,
    endDate: schoolData.school.endDate,
  };
  return schoolVM;
});

/**
 * returns all teachers, teams, and the school itself, that were active in any schoolyear (current, past or future)
 */
export const selectAllCreatorsForSchoolThatEverExisted = createTypedSelector(
  [selectAllTeachers, selectAllTeams, selectMiniSchoolVM],
  (teachers, teams, school) => {
    if (!school) return [];
    return [school, ...teams, ...teachers];
  }
);

const selectTeachersActiveInSchoolyear = createTypedSelector(
  [
    (state) => selectCurrentSchoolData(state)?.responsibilities,
    (state, params?: { schoolyear: string }) => selectSchoolyearFromParamOrCurrent(state, params),
    (state) => selectCurrentSchoolData(state)?.teachers,
    // (state) => selectLastActiveDateForSchool(state, selectCurrentSchoolHref(state)),
  ],
  (responsibilities, schoolyear, allTeachers) => {
    if (!responsibilities || !schoolyear) return [];
    const activeResps = filterResourcesActiveInSchoolyear(responsibilities, schoolyear);
    const activeTeacherHrefsSet = new Set(activeResps.map((z) => z.person.href));
    return allTeachers
      .filter((teacher) => activeTeacherHrefsSet.has(teacher.$$meta.permalink))
      .map((teacher) => getTeacherVm(teacher));
  }
);

const selectTeamsActiveInSchoolyear = createTypedSelector(
  [
    (state) => selectCurrentSchoolData(state)?.teacherGroups,
    (state, params?: { schoolyear: string }) => selectSchoolyearFromParamOrCurrent(state, params),
  ],
  (teacherGroups, schoolyear) => {
    if (!teacherGroups || !schoolyear) return [];
    const result = filterResourcesActiveInSchoolyear(teacherGroups, schoolyear).map((team) =>
      getTeamVm(team)
    );
    return result;
  }
);

/**
 * returns all teachers, teams, and the school itself, that were active in the current schoolyear
 */
export const selectAllCreatorsActiveInSchoolyear = createTypedSelector(
  [selectTeachersActiveInSchoolyear, selectTeamsActiveInSchoolyear, selectMiniSchoolVM],
  (teachers, teams, school) => {
    if (!school) return [];
    return [school, ...teams, ...teachers];
  }
);

export const selectTeamsWithTeachersActiveInSchoolyear = createTypedSelector(
  [
    selectTeamsActiveInSchoolyear,
    (state) => selectCurrentSchoolData(state)?.responsibilitiesPerTeam,
    selectTeachersActiveInSchoolyear,
  ],
  (teacherGroups, responsibilitiesPerTeam, teachers) => {
    if (!teacherGroups) return [];
    return teacherGroups.map((team) => {
      const teachersOfTeam = teachers.filter((teacher) =>
        responsibilitiesPerTeam[team.$$meta.permalink]?.some(
          (resp) => resp.person.href === teacher.$$meta.permalink
        )
      );
      return {
        ...team,
        teachers: teachersOfTeam,
      };
    });
  }
);

export const selectOrgsTeacherMapForSchoolyear = createTypedSelector(
  [
    selectCurrentSchoolHref,
    selectTeachersActiveInSchoolyear,
    selectTeamsWithTeachersActiveInSchoolyear,
  ],
  (schoolHref, teachers, teams) => {
    const orgTeachersMap = {};
    orgTeachersMap[schoolHref] = teachers.map((e) => e.$$meta.permalink);
    teachers.forEach((teacher) => {
      orgTeachersMap[teacher.$$meta.permalink] = [teacher.$$meta.permalink];
    });
    teams.forEach((team) => {
      orgTeachersMap[team.$$meta.permalink] = team.teachers.map((e) => e.$$meta.permalink);
      // orgTeachersMap[team.$$meta.permalink].push(team.$$meta.permalink); I HOPE THIS DOESN'T BREAK ANYTHING when we comment it out
    });
    return orgTeachersMap;
  }
);

export const selectPrincipalTeamHrefsForSchoolyear = createTypedSelector(
  [
    (state, params?: { schoolyear: string }) => selectTeamsActiveInSchoolyear(state, params),
    (state, params?: { schoolyear: string }) =>
      selectActiveSamResponsibilitiesForSchoolInSchoolyear(state, params),
  ],
  (currentSchoolTeacherGroups, samResponsibilitiesForSchoolyear) => {
    if (!currentSchoolTeacherGroups) {
      return [];
    }

    const teamHrefs = new Set();

    currentSchoolTeacherGroups.forEach((t) => teamHrefs.add(t.$$meta.permalink));

    const teamBossResps = samResponsibilitiesForSchoolyear.filter(
      (resp) =>
        teamHrefs.has(resp.organisation.href) &&
        resp.position &&
        (resp.position.href === positions.coordinator || resp.position.href === positions.beheerder)
    );

    return teamBossResps.map((r) => r.organisation.href);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectPrincipalTeamsForSchoolyear = createTypedSelector(
  [selectPrincipalTeamHrefsForSchoolyear, selectTeamsActiveInSchoolyear],
  (principalTeamHrefs, teacherGroups) => {
    if (!teacherGroups) return [];
    const principalTeams = teacherGroups.filter((z) =>
      principalTeamHrefs.includes(z.$$meta.permalink)
    );
    return principalTeams;
  }
);

/**
 * teams of which you are a member in this schoolyear
 */
export const selectMemberTeamsForSchoolyear = createTypedSelector(
  [
    (state, params?: { schoolyear: string }) =>
      selectActiveSamResponsibilitiesForSchoolInSchoolyear(state, params),
    selectTeamsActiveInSchoolyear,
  ],
  (activeSamResps, teacherGroups) => {
    if (!teacherGroups) return [];
    const memberTeams = teacherGroups.filter((z) =>
      activeSamResps.some((r) => r.organisation.href === z.$$meta.permalink && r.position)
    );
    return memberTeams;
  }
);

/**
 * the orgs for which the user can read the customcurricula / activityplans
 * there is a special exception for teamleads, but that is handled in the respective selectors of leerplanList and calendarlist.
 */
export const selectUserOrgsForSchool = createTypedSelector(
  [
    selectTeachersActiveInSchoolyear,
    selectTeamsActiveInSchoolyear,
    selectMemberTeamsForSchoolyear,
    selectMiniSchoolVM,
    selectUser,
    selectIsDirectorInCurrentSchool,
  ],
  (teachers, allTeams, memberTeams, school, user, isDirector) => {
    if (!school || !user) return [];
    const schCreators: VmCreator[] = [];
    schCreators.push(school);
    if (isDirector) {
      schCreators.push(...allTeams);
    } else {
      schCreators.push(...memberTeams);
    }

    if (isDirector) {
      schCreators.push(...teachers);
    } else {
      const vmPerson = teachers.find((t) => t.$$meta.permalink === user.$$meta.permalink);
      if (vmPerson) {
        schCreators.push(vmPerson);
      }
    }

    return schCreators;
  }
);

export const selectOrgsInfoForLeerplanList = createTypedSelector(
  [
    selectUserOrgsForSchool,
    selectAllCreatorsActiveInSchoolyear,
    (state) => selectCurrentSchoolData(state)?.school,
    (state) => selectCurrentSchoolData(state)?.studyProgrammes,
    (state) => selectCurrentSchoolData(state)?.studyProgrammeGroups,
    selectPrincipalTeamHrefsForSchoolyear,
    selectTeamsWithTeachersActiveInSchoolyear,
  ],
  (
    userOrgs,
    allCreators,
    school,
    studyProgrammes,
    studyProgrammeGroups,
    principalTeamHrefs,
    teamsWithTeachers
  ) => {
    let schoolStudyProgrammes: string[] = [];
    const orgs = allCreators;
    let schoolHref;
    const principalTeamsWithTeachers = teamsWithTeachers.filter((z) =>
      principalTeamHrefs.includes(z.href)
    );

    if (school) {
      schoolHref = school.$$meta.permalink;
      schoolStudyProgrammes = studyProgrammes.map((e) => e.$$meta.permalink);
    }

    return {
      userOrgs,
      orgs,
      schoolHref,
      schoolStudyProgrammes,
      studyProgrammeGroups,
      principalTeamsWithTeachers,
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectBossOrgHrefs = createTypedSelector(
  [selectPrincipalTeamHrefsForSchoolyear, selectPrincipalSchoolHrefs, selectUserHref],
  (principalTeamHrefs, principalSchoolHrefs, userHref) => {
    if (!userHref) return [];

    const bossOrgs = [userHref].concat(principalTeamHrefs).concat(principalSchoolHrefs);

    return bossOrgs;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectCurrentOrg = createTypedSelector(
  [selectAllCreatorsActiveInSchoolyear, (state) => state.userAndSchools.currentOrgHref],
  (currentOrgs, currentOrgHref) => {
    return currentOrgs.find((org) => org.href === currentOrgHref);
  }
);

export const selectIsUserSchoolValid = createTypedSelector(
  [selectSchoolVMs, selectActiveClassesInSchoolyear],
  (schools, classesForSchool) => {
    let alert;
    let hasValidSchools = true;

    if (!schools || schools?.length === 0) {
      console.warn('this person is not linked to any schools in SAM. calendar unavailable.');
      alert = {
        key: 'no-school-error',
        title: 'Fout',
        msg: 'Je bent niet aan een school verbonden. Om vorderingsplannen te maken dient jouw directeur je aan de school toe te voegen.',
        type: 'error',
      };

      hasValidSchools = false;
    } else if (classesForSchool?.length === 0) {
      console.warn('there are no classes for the school for this user. calendar unavailable.');
      alert = {
        key: 'no-classes-error',
        title: 'Fout',
        msg: 'Voor jouw school zijn de klassen nog niet ingevuld. Om vorderingsplannen te maken dient jouw directeur de klassen in te voeren.',
        type: 'error',
      };

      hasValidSchools = false;
    }

    return {
      hasValidSchools,
      alert,
    };
  }
);

export const selectIsSchoolReadOnly = (state: RootState, href) => {
  const lastActiveDate = selectLastActiveDateForSchool(state, href);

  if (lastActiveDate === null) return false;
  const lastActiveDay = new Date(lastActiveDate);
  // readonly if the last active date is in the past and not today
  return isPast(lastActiveDay) && !isToday(lastActiveDay);
};

export const selectIsCurrentSchoolReadOnly = (state: RootState) => {
  return selectIsSchoolReadOnly(state, selectCurrentSchoolHref(state));
};

export const selectReadOnlySchools = createTypedSelector(
  [selectLastActiveDateForSchools, () => new Date().toDateString()],
  (lastActiveDateMap, _today) => {
    return Object.entries(lastActiveDateMap).reduce<string[]>(
      (acc, [schoolHref, lastActiveDate]) => {
        if (lastActiveDate === null) return acc;
        const lastActiveDay = new Date(lastActiveDate);
        if (isPast(lastActiveDay) && !isToday(lastActiveDay)) {
          acc.push(getKeyFromHref(schoolHref));
        }
        return acc;
      },
      []
    );
  }
);

export const selectIsSchoolyearReadOnly = createTypedSelector(
  [
    (state, params?: { schoolyear: string }) => selectSchoolyearFromParamOrCurrent(state, params),
    selectIsCurrentSchoolReadOnly,
  ],
  (schoolyear, isSchoolReadOnly) => {
    if (!schoolyear) return null;

    if (isSchoolReadOnly) return true;

    if (schoolyear.expired) return true;

    return false;
  }
);
