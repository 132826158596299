import commonUtils from '@kathondvla/sri-client/common-utils';
import { omit } from 'lodash-es';
import settings from '@config/settings';

/** Types for content API items. * */
export const CONTENTTYPES = {
  curriculum: 'LLINKID_CURRICULUM',
  principleGroup: 'LLINKID_GUIDING_PRINCIPLE_GROUP',
  principle: 'LLINKID_GUIDING_PRINCIPLE',
  goalSection: 'LLINKID_GOAL_SECTION',
  goalList: 'LLINKID_GOAL_LIST',
  goal: 'LLINKID_GOAL',
  removedGoal: 'LLINKID_REMOVED_GOAL',
  tip: 'LLINKID_PEDAGOGICAL_TIP',
  demarcation: 'LLINKID_GOAL_DEMARCATION',
  demarcGoalContent: 'LLINKID_GOAL_CONTENT',
  staticpart: 'LLINKID_STATIC_PART',
  staticpartParagraph: 'PARAGRAPH',
  basis: 'CURRICULUM_ODET_DEVELOPMENT_GOAL',
  tabs: {
    inleiding: 'STRUCTURED_DOCUMENT',
    situering: 'LLINKID_CONTEXT',
    duiding: 'LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT',
    doelenlijst: 'LLINKID_GOAL_LIST',
    basisuitrusting: 'LLINKID_BASIC_MATERIAL_REQUIREMENTS',
    concordantielijst: 'LLINKID_CORRELATION_ENDTERMS',
  },
  initialSituation: 'LLINKID_INITIAL_SITUATION',
  markExplanation: 'MARK_EXPLANATION',
  goalExplanation: 'LLINKID_GOAL_EXPLANATION',
  goalExtra: 'LLINKID_EXTRA_GOAL_INFORMATION',
  concordance: 'LLINKID_CONCORDANCE',
  concretization: 'LLINKID_CONCRETIZATION',
};

export const LLINKID_GOAL_TYPES = {
  REGULAR: 'REGULAR',
  DEEPENING: 'DEEPENING',
  EXTRA: 'EXTRA',
  CHOICE: 'CHOICE',
  CUSTOM: 'CUSTOM',
};

export const TABNAMES = {
  inleiding: 'ALGEMENE INLEIDING',
  situering: 'SITUERING',
  duiding: 'PEDAGOGISCH-DIDACTISCHE DUIDING',
  basisuitrusting: 'BASISUITRUSTING',
  concordantielijst: 'CONCORDANTIELIJST',
  doelenlijst: 'LEERPLAN',
  customplan: 'MIJN LEERPLAN',
  distributie: 'DOELENLIJST',
  selector: 'SELECTOR',
};

/** Types for custom annotations. * */
export const CUSTOMCURRICULATYPES = {
  adaptation: 'ADAPTATION',
  anonymousSchool: 'ANONYMOUS_SCHOOL',
  anonymousProject: 'ANONYMOUS_PROJECT',
  custom: 'CUSTOM',
};

/** Types for of Custom Curricula. * */
export const CUSTOMTYPES = {
  inclusion: 'INCLUSION',
  exclusion: 'EXCLUSION',
  goalReference: 'REFERENCE',
  goalMove: 'MOVE',
  goal: 'CUSTOM_GOAL',
  tip: 'CUSTOM_TIP',
  note: 'NOTE',
  educationalPointer: 'EDUCATIONAL_POINTER',
  reposition: 'REPOSITION',
  goalList: 'CUSTOM_GOAL_LIST',
  section: 'SECTION',
};

export const CUSTOMCREATIONTYPES = {
  customGoal: 'GOAL',
  llinkidGoal: 'LLINKID_GOAL',
  section: 'SECTION',
  subSection: 'SUB_SECTION',
};

export const RELATION_TYPES = {
  replaces: 'REPLACES',
};

export const getChildren = (item, itemsMap) => {
  return item.childrenHrefs?.map((e) => itemsMap[e]);
};

const addChildrenRecursive = (item, itemsMap) => {
  return {
    ...item,
    children: item.childrenHrefs?.map((e) => addChildrenRecursive(itemsMap[e], itemsMap)),
    childrenHrefs: undefined,
  };
};

export const getCustomGoalList = () => {
  return {
    $$meta: {
      permalink: null,
    },
    href: null,
    type: CUSTOMTYPES.goalList,
    children: [],
  };
};

export const getHighDemarcations = (resource, extractedCurriculum) => {
  const { children } = addChildrenRecursive(resource, extractedCurriculum);
  if (!children) return [];

  const result = [];

  children
    .filter((e) => e.type === CONTENTTYPES.demarcation && e.importance === 'HIGH')
    .forEach((child) => {
      result.push({ ...child, items: child.children });
    });

  return result;
};

export const excludeMandatoryResources = (items) => {
  const newItems = {};
  Object.keys(items).forEach((href) => {
    const curItem = items[href];
    const excluded =
      !curItem.mandatory &&
      (curItem.type === CONTENTTYPES.goal ||
        curItem.type === CUSTOMTYPES.goal ||
        curItem.type === CONTENTTYPES.tip ||
        curItem.type === CONTENTTYPES.goalExtra ||
        curItem.type === CONTENTTYPES.goalExplanation ||
        curItem.type === CONTENTTYPES.initialSituation);
    newItems[href] = { ...curItem, excluded };
  });
  return newItems;
};

export const flatRefrencedCurriculaItems = (curr) => {
  let items = [curr];
  curr.children?.forEach((c) => {
    items = items.concat(flatRefrencedCurriculaItems(c));
  });
  return items;
};

export const flattenAndExtractCurriculaItems = (curr, itemsMap = {}, readOrder = undefined) => {
  const { children, ...childlessCurr } = curr;
  itemsMap[curr.href] = {
    ...childlessCurr,
    childrenHrefs: children?.map((e) => e.href),
    readOrder,
  };
  children?.forEach((c, index) => {
    flattenAndExtractCurriculaItems(c, itemsMap, index);
  });
  return itemsMap;
};

export const filterCurrByName = (curricula, text) => {
  const loweredText = text?.toLocaleLowerCase();
  if (!loweredText || loweredText === '') return curricula;

  return curricula.filter((c) => c.$$name.includes(loweredText));
};

export const translateStudyProgrammesToString = (studyProgrammesHrefs, allPrograms) => {
  return allPrograms
    .filter((e) => studyProgrammesHrefs.includes(e.$$meta.permalink))
    .map((e) => e.title)
    .join(', ');
};

export function createCustomItem(schoolHref, creatorHref, customItemProperties) {
  const key = commonUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/llinkid/customcurriculum/customitems/${key}`,
    },
    collapsed: false,
    deepening: false,
    identifier: null,
    importance: 'MEDIUM',
    key,
    mandatory: false,
    editable: true,
    creator: { href: creatorHref },
    context: { href: schoolHref },
    ...customItemProperties,
  };
}

export function setCollapsedSections(entities, collapsedSections) {
  if (!collapsedSections) {
    return entities;
  }

  const newEntities = { ...entities };

  Object.values(newEntities)
    .filter((e) => e.type === CONTENTTYPES.goalSection || e.type === CUSTOMTYPES.section)
    .forEach((item) => {
      if (collapsedSections[item.key]) {
        newEntities[item.href] = { ...newEntities[item.href], collapsed: true };
      } else {
        newEntities[item.href] = { ...newEntities[item.href], collapsed: false };
      }
    });

  return newEntities;
}

export function modifyGoalsForDistribution(entities) {
  const newEntities = { ...entities };
  Object.values(newEntities)
    .filter((e) => e.type === CONTENTTYPES.goal || e.type === CUSTOMTYPES.goal)
    .forEach((item) => {
      const goal = omit(item, [
        'educationalPointers',
        'relatedGoals',
        'implementsGoals',
        'partiallyImplementsGoals',
        'odetGoals',
        'highDemarcations',
      ]);

      newEntities[item.href] = {
        ...goal,
        childrenHrefs: [],
      };
    });

  const hiddenTypesForDistribution = [
    CONTENTTYPES.concretization,
    CONTENTTYPES.tip,
    CONTENTTYPES.goalExtra,
    CONTENTTYPES.goalExplanation,
  ];

  Object.values(newEntities)
    .filter((e) => hiddenTypesForDistribution.includes(e.type))
    .forEach((item) => {
      newEntities[item.href] = {
        ...item,
        hidden: true,
      };
    });

  return newEntities;
}

export function getStudyProgrammeTypeNameForGrade(grade) {
  return grade === 1 ? 'basisopties' : 'studierichtingen';
}

export function getImageUrl(grade, attachments) {
  const gradeNumber = grade || 4;
  const defaultImg = `/headers/headerbg${gradeNumber}.png`;

  const url = attachments?.find((att) => att.type, 'COVER_IMAGE') || {};
  return url.href ? settings.apisAndUrls.cachedApi.baseUrl + url.href : defaultImg;
}
